






























































import {BaseTemplate} from "@/modules/budget/staffing-table/reports/templates/base-template";
import i18n from "@/services/i18n";
import {store} from "@/modules/budget/staffing-table/common";
import {BudgetVariants, Dict, Org} from "@/modules/budget/staffing-table/types";
import {Component} from "vue-property-decorator";
import {Overlay} from "@/modules/budget/staffing-table/components";
import MultiBudgetProgramSelectionTemp from "@/modules/budget/staffing-table/budget-programs/MultiBudgetProgramSelectionTemp.vue";
import {SingleBudgetProgramField} from "@/modules/budget/staffing-table/budget-programs";
import {SingleBudgetVariantField} from "@/modules/budget/staffing-table/budget-variants";
import {SingleOrgField} from "@/modules/budget/staffing-table/organizations";
import {SingleVersionField} from "@/modules/budget/staffing-table/versions";
import MultiSpecificityFormField from "@/modules/budget/staffing-table/ebk-ek/MultiSpecificityFormField.vue";
import {Expression, prepareForBackend} from "@/components/custom/bool-ex";

const LocalTranslates = {
    templates: {
        budgetProgramReport: {
            get title(): string {
                return i18n.choose(
                    'Данный шаблон предназначен для отображения всех отчетов по выбранной версии бюджета и бюджетной программе',
                    'Бюджеттік версия пен бюджеттік бағдарлама бойынша таңдалған барлық отчеттерді көрсету үшін бұл үлгі құрылған',
                    'This template is designed to display all reports for the selected budget version and budget program',
                );
            },
        },
    },
};

// region Ключ шаблона
const templateKey  = 'budget-program-reports'

@Component({
    components: {
        Overlay,
        MultiBudgetProgramSelectionTemp,
        SingleBudgetProgramField,
        SingleBudgetVariantField,
        SingleOrgField,
        SingleVersionField,
        MultiSpecificityFormField
    },
})
export default class BudgetProgramTemplate extends BaseTemplate {

    // region Утилиты
    public translates = LocalTranslates;

    // region Организация, ГУ, КГКП, АБП
    public org: Org | null = store.org;

    public reportYear = store.reportYear;

    public selectedBudgetProgram = store.budgetProgram

    public budgetVariant: BudgetVariants | null = store.budgetVariant;

    public get loading(): boolean {
        return this.variantLoading
    }

    public variantLoading = false;


    private created() {

        this.$watch('reportYear', () => {
            store.reportYear = this.reportYear
            this.updateBudgetVariant(this.org)
            this.selectedBudgetProgram = null
        })

        this.$watch('org', (org: Org | null) => {
            store.org = org
            this.updateBudgetVariant(org)
            this.emitNullExpressionToParent()

        })

        this.$watch('selectedBudgetProgram', () => {
            store.budgetProgram = this.selectedBudgetProgram
        })

        this.$watch('budgetVariant', () => {
            store.budgetVariant = this.budgetVariant
            this.emitNullExpressionToParent()
        })
    }

    get abpCode(): number | null {
        const guCode = this.gu?.code ?? null;

        if (guCode === null) {
            return null;
        }
        if (guCode.length < 3) {
            return null;
        }

        const abpCodeString = guCode.substring(0, 3);
        const abpCode = parseInt(abpCodeString);

        if (String(abpCode) !== abpCodeString) {
            console.error(`GU code "${guCode}" contains invalid ABP code "${abpCodeString}"`);
            return null;
        }
        return abpCode;
    }

    public get gu(): Dict.Gu | null {
        const org = this.org;
        if (org === null) {
            return null;
        }

        const type = org.type;
        switch (org.type) {
            case 'KGKP':
                return org.gu;
            case 'GU':
                return org.gu;
            default:
                console.error(`Unexpected org type \"${type}\"`);
                return null;
        }
    }

    public resetParentTemplateKey() {
        this.$emit('reset-selected-templateKey');
    }

    public validateNulls(): boolean {
        const codeOrg = this.getOrgCode
        const uuid = this.budgetVariant?.variantUuid ?? null
        const isEmptyBudgetProg = this.budgetVariant?.variantUuid ?? null
        const isEmptyBudgetProgram = this.selectedBudgetProgram ?? null

        return uuid === null || isEmptyBudgetProg === null || isEmptyBudgetProgram === null || codeOrg === null
    }

    private get createBudgetVariantUuid(): string | null {
        return this.budgetVariant?.variantUuid ?? null
    }

    private get createBudgetProgVariants(): number | null {
          return this.selectedBudgetProgram?.prg ?? null
        };

    private get getOrgCode(): string | null {
        return this.org?.org.code ?? null
    }

    emitNullExpressionToParent() {
        this.$emit('expression-to-parent', null, templateKey);
    }

    emitExpressionToParent() {
        const item = this.expressionItem
        prepareForBackend(item)
        this.$emit('expression-to-parent', item, templateKey);
    }

    async updateBudgetVariant(org: Org | null) {
        this.variantLoading = true
        this.budgetVariant = org ? await this.setActualVariant(org, this.reportYear) : null;
        this.variantLoading = false
    }

    get expressionItem(): Expression<boolean> {
        return {
            type: 'boolean-group',
            dataType: 'boolean',
            operation: 'AND',
            items: [
                {
                    type: 'two-value-comparison',
                    dataType: 'boolean',
                    first: {
                        type: 'parameter',
                        dataType: 'string',
                        value: this.getOrgCode
                    },
                    operation: 'equals',
                    second: {
                        type: 'custom-field',
                        dataType: 'string',
                        key: 'version--org-code',
                        title: 'code org'
                    }
                },
                {
                    type: 'two-value-comparison',
                    dataType: 'boolean',
                    first: {
                        type: 'parameter',
                        dataType: 'string',
                        value: this.createBudgetVariantUuid
                    },
                    operation: 'equals',
                    second: {
                        type: 'custom-field',
                        dataType: 'string',
                        key: 'budget-variant-uuid',
                        title: 'Budget Variant UUID'
                    }
                },
                {
                    type: 'two-value-comparison',
                    dataType: 'boolean',
                    first: {
                        type: 'parameter',
                        dataType: 'number',
                        value: this.createBudgetProgVariants
                    },
                    operation: 'equals',
                    second: {
                        type: 'custom-field',
                        dataType: 'number',
                        key: 'budg-prog-code',
                        title: 'Budget Subprogram Code'
                    }
                }
            ]
        };
    }
}

