


























































import {BaseTemplate} from "@/modules/budget/staffing-table/reports/templates/base-template";
import {Component} from "vue-property-decorator";
import {Overlay} from "@/modules/budget/staffing-table/components";
import {SingleBudgetProgramField} from "@/modules/budget/staffing-table/budget-programs";
import {SingleBudgetVariantField} from "@/modules/budget/staffing-table/budget-variants";
import {SingleOrgField} from "@/modules/budget/staffing-table/organizations";
import {SingleVersionField} from "@/modules/budget/staffing-table/versions";
import {BudgetVariants, Org, Version} from "@/modules/budget/staffing-table/types";
import * as common from "@/modules/budget/staffing-table/common";
import {store} from "@/modules/budget/staffing-table/common";
import {dataTypes, Expression, prepareForBackend} from "@/components/custom/bool-ex";
import i18n from "@/services/i18n";

const LocalTranslates = {
    templates: {
        variantBudgetStaffTabVersionReports: {
            get title(): string {
                return i18n.choose(
                    'Шаблон для отображения всех отчетов по выбранной версии штатного расписания и версии бюджета',
                    'Барлық есептерді таңдалған штаттық кесте нұсқасы мен бюджет нұсқасы бойынша көрсетуге арналған шаблон',
                    'Template for displaying all reports by selected staffing schedule version and budget version',
                );
            },
        },
    },
};

// region Ключ шаблона
const templateKey  = 'variant-budget-staff-version-reports'


@Component({
    components: {
        Overlay,
        SingleBudgetProgramField,
        SingleBudgetVariantField,
        SingleOrgField,
        SingleVersionField,
    },
})
export default class BudgetVariantAndStaffTabVersionTemplate extends BaseTemplate {

    // region Утилиты
    public i18n = i18n;

    // region Утилиты
    public translates = LocalTranslates;

    private created() {
        this.$watch('org', (org: Org | null) => {
            store.org = org;
            this.resetStaffTabVersion()
            this.updateBudgetVariant(org)
            this.emitNullExpressionToParent()
        });

        this.$watch('version', () => {
            store.version = this.version
        })

        this.$watch('reportYear', () => {
            store.reportYear = this.reportYear;
            this.updateBudgetVariant(this.org)
        });

        this.$watch('budgetVariant', () => {
            store.budgetVariant = this.budgetVariant
            this.emitNullExpressionToParent()
        })
    }

    // region Организация, ГУ, КГКП, АБП
    public org: Org | null = common.store.org;

    // region Версия Шр
    public version: Version | null = store.version;

    public reportYear = store.reportYear;

    public budgetVariant: BudgetVariants | null = store.budgetVariant;

    get expressionItem(): Expression<boolean> {
        return {
            type: 'boolean-group',
            dataType: 'boolean',
            operation: 'AND',
            items: [
                {
                    type: 'two-value-comparison',
                    dataType: 'boolean',
                    first: {
                        type: 'parameter',
                        dataType: 'number',
                        value: this.getStaffTabVersionId
                    },
                    operation: 'equals',
                    second: {
                        type: 'custom-field',
                        dataType: 'number',
                        key: 'version-id',
                        title: 'staffTab version'
                    }
                },
                {
                    type: 'two-value-comparison',
                    dataType: dataTypes.boolean,
                    first: {
                        type: 'parameter',
                        dataType: 'string',
                        value: this.getBudgetVariantUuid
                    },
                    operation: 'equals',
                    second: {
                        type: 'custom-field',
                        dataType: 'string',
                        key: 'budget-variant-uuid',
                    }
                } as Expression<boolean>
            ]
        }
    }

// region Общие данные
    public get loading(): boolean {
        return this.variantLoading
    }

    public variantLoading = false;

    private get getBudgetVariantUuid(): string | null {
        return this.budgetVariant?.variantUuid ?? null
    }

    public validateNulls(): boolean {
        const uuid = this.budgetVariant?.variantUuid ?? null
        const isEmptyStaffTab = this.version
        return uuid == null || isEmptyStaffTab == null
    }

    private resetBudgetVariant() {
        this.budgetVariant = null
    }

    private resetStaffTabVersion() {
        this.version = null
    }

    private get getStaffTabVersionId(): number | null {
        return this.version?.id ?? null
    }

  public resetParentTemplateKey() {
        this.$emit('reset-selected-templateKey');
    }

    async updateBudgetVariant(org: Org | null) {
        this.variantLoading = true
        this.budgetVariant = org ? await this.setActualVariant(org, this.reportYear) : null;
        this.variantLoading = false
    }

    emitNullExpressionToParent() {
        this.$emit('expression-to-parent', null, templateKey);
    }

    emitExpressionToParent() {
        const item = this.expressionItem
        prepareForBackend(item)
        this.$emit('expression-to-parent', item, templateKey);
    }

}
