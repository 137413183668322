



























import {Component, Vue} from 'vue-property-decorator';
import { ComponentBase } from '../components';
import VariantBudgetTemplate from "@/modules/budget/staffing-table/reports/templates/VariantBudgetTemplate.vue";
import {Utils} from "@/modules/budget/staffing-table/types";
import TemplateKey = Utils.TemplateKey;
import type {BoolEx} from "@/modules/budget/staffing-table/reports/types";
import {store} from "@/modules/budget/staffing-table/common";
import FormTemplate from "@/modules/budget/staffing-table/reports/templates/FormTemplate.vue";
import i18n from "@/services/i18n";
import SpecificityTemplate from "@/modules/budget/staffing-table/reports/templates/SpecificityTemplate.vue";
import BudgetProgramTemplate from "@/modules/budget/staffing-table/reports/templates/BudgetProgramTemplate.vue";
import BudgetVariantAndStaffTabVersionTemplate from "@/modules/budget/staffing-table/reports/templates/BudgetVariantAndStaffTabVersionTemplate.vue";

const Translates = {
    optionsReports: {
        variantBudgetReports: {
            get title(): string {
                return i18n.choose(
                    'Перечень отчетов по версии бюджета',
                    'Бюджет нұсқасы бойынша есептер',
                    'Reports by budget version',);
            },
        },
        budgetProgramReports: {
            get title(): string {
                return i18n.choose(
                    'Перечень отчетов по бюджетной программе',
                    'Бюджеттік бағдарлама бойынша есептер',
                    'Reports on the budget program',);
            },
        },
        variantSpecificityReports: {
            get title(): string {
                return i18n.choose(
                    'Перечень отчетов по спецификам',
                    'Ерекшеліктер бойынша есептер',
                    'Reports by Specifics');
            },
        },
        variantFormsReports: {
            get title(): string {
                return i18n.choose(
                    'Перечень отчетов по формам',
                    'Формалар бойынша есептер',
                    'Reports by Forms');
            },
        },
        budgetVariantStaffTabVersion: {
            get title(): string {
                return i18n.choose(
                    'Перечень отчетов по версии штатного расписания и версии бюджета',
                    'Штаттық кесте нұсқасы мен бюджет нұсқасына қатысты есептер',
                    'Reports by staffing table version and budget version');
            },
        }
    },
};

interface TemplateInfo {
    component: typeof Vue
    title: string,
}


@Component({
    components: {
        VariantBudgetTemplate,
        FormTemplate,
        SpecificityTemplate,
        BudgetVariantAndStaffTabVersionTemplate
    },
})

export default class FilterSortPane extends ComponentBase {
    constructor() {
        super('');
    }

     created() {
        super.created()
    }
    // region Утилиты
    public translates = Translates;

    // region Варианты шаблонов
    public readonly templateInfoMap: Record<TemplateKey, TemplateInfo> = {
        'variant-budget-reports': {
            component: VariantBudgetTemplate,
            title: this.translates.optionsReports.variantBudgetReports.title
        },
        'variant-budget-staff-version-reports': {
            component: BudgetVariantAndStaffTabVersionTemplate,
            title: this.translates.optionsReports.budgetVariantStaffTabVersion.title
        },
        'budget-program-reports': {
            component: BudgetProgramTemplate,
            title: this.translates.optionsReports.budgetProgramReports.title
        },
        'specificity-reports': {
            component: SpecificityTemplate,
            title: this.translates.optionsReports.variantSpecificityReports.title
        },
        'form-reports': {
            component: FormTemplate,
            title: this.translates.optionsReports.variantFormsReports.title
        }
    }

    public selectedTemplateKey = store.templateKey;
    public readonly templateKeys: TemplateKey[] = ['variant-budget-reports', 'variant-budget-staff-version-reports', 'budget-program-reports', 'specificity-reports', 'form-reports'];



    public someMethod() {
        // Использование методов из ComponentBase
        this.toast('success', 'Title', 'Message');
        const url = this.prepareUrl('/api/data', [['param1', 'value1']]);
        this.openUrl(url);
    }

    resetSelectedTemplateKey() {
        this.selectedTemplateKey = null;
    }

    // Method to emit updated condition
    private updateCondition(newCondition: BoolEx.Expression<boolean> | null, templateKey: TemplateKey) {
        this.$emit('update-condition', newCondition, templateKey);
    }

    handleExpressionFromChild(expression: BoolEx.Expression<boolean> | null, templateKey: TemplateKey) {
        this.updateCondition(expression, templateKey)

    }
}
